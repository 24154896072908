@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: Mulish, sans-serif;
}

/* ios date picker styling fix */
input[type="date"]
{
    min-width:95%;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}

.overflow-ios-fix::-webkit-scrollbar {
  display: none;
}

.firebase-emulator-warning {
  display: none;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

[data-theme="tuokis"] ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.highscore-table .even td {
  @apply bg-base-200 bg-opacity-70;
}

.highscore-table .odd td {
  @apply bg-base-100 bg-opacity-90;
}

[data-theme="tuokis"] .highscore-table table thead tr th {
  @apply bg-base-300;
}

[data-theme="tuokis"] .highscore-table table {
  @apply font-light;
}

.tooltip-premium-tight::before {
  @apply w-32 ml-12;
}

.tooltip-tight-right::before {
  @apply max-w-2xs -ml-24;
}

.tooltip-tight-left::before {
  @apply max-w-2xs ml-24;
}

[data-theme="tuokis"] .tooltip-premium {
  --tooltip-color: #ce9c3b;
  --tooltip-text-color: #fff;
}

[data-theme="tuokis"] h1, h2, h3, h4, h5, h6 {
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
}

@media all and (min-width: 1024) {
  .pswp__img {
    margin-top: 1rem !important;

  }
  .pswp__button--share-button {
    display: none !important;
  }
}

@media all and (min-width: 768px) {
  .pswp__img {
    margin-top: 2rem !important;
  }
  .pswp__button--share-button {
    display: none !important;
  }
}

.pswp__img {
  margin-top: 8rem;
  height: auto !important;
}

.pswp__button--share-button {
  display: block;
  margin-top: 4px !important;
  margin-left: 8px !important;
}

.pswp__button--delete-button {
  display: block;
  margin-top: 5px !important;
  margin-left: 7px !important;
  margin-right: -5px !important;
}

.pswp {
  z-index: 40 !important;
}

.text-stroke-3 {
  text-shadow: -1px -1px 0 #b79650, 1px -1px 0 #b79650, -1px 1px 0 #b79650, 1px 1px 0 #b79650;
}

.team-row-name::-webkit-scrollbar {
  display: none;
}

input::selection {
  @apply bg-base-200;
}

[data-theme="camdeed"] .navbar {
  @apply text-white bg-secondary border-secondary;
}

[data-theme="tuokis"] .navbar {
  @apply bg-base-200 border-base-300;
}

[data-theme="tuokispremium"] .navbar {
  @apply bg-base-300 border-base-300;
}

[data-theme="camdeed"] .dropdown-content {
  @apply text-black;
}

[data-theme="tuokis"] .tooltip {
  --tooltip-color: #3d4451;
  --tooltip-text-color: #faf8f4;
}

[data-theme="tuokis"] .modal-window > .tooltip {
  @apply z-50;
}

[data-theme="tuokis"] *:focus {
  @apply !outline-primary;
}

[data-theme="tuokis"] input:not([type='checkbox']):not([type='radio']), textarea, select {
  @apply !border-base-300 !bg-white text-black;
}

[data-theme="tuokis"] .contact-input input, textarea{
  @apply border-base-100 !bg-base-100;
}

[data-theme="tuokis"] input:disabled {
  @apply !bg-base-200 !bg-opacity-50 !text-slate-400;
}

[data-theme="tuokis"] button:disabled {
  @apply bg-opacity-10;
}

[data-theme="tuokis"] .no-button-animation {
  transition-timing-function:cubic-bezier(1, 0, 0, 1) !important;
  transition-property: none !important;
  transition-duration: 0s !important;
  transition-timing-function: none !important;
  animation: none !important;
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
} 

textarea:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset !important; /* Change the color to your own background color */
  @apply !bg-white;
}

textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  @apply !bg-white;
} 

.blockspam {
  display: none;
}

.fadein {
  animation: fadeInAnimation ease 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
@keyframes bounce-horizontal-inertia {
  0%, 100% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(25%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-bounce-horizontal-inertia {
  animation: bounce-horizontal-inertia 1s infinite;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.pswp__button--play-button {
  position: absolute;
  top: 40vh;
  left: 50vw;
  z-index: 1000;
  margin-left: -16px !important;
}

.pswp__button--play-button .pswp__icn {
  @apply -ml-4 -mt-4 w-20 h-20 !important;
}

.video-player {
  top: 10vh;
  padding: 2rem;
  position: fixed;
  z-index: 1002;
  display: flex;
  justify-content: center;
  max-height: 80vh;
  height: 100%;
  width: 100%;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1001;
}

.textarea-overlay {
  background-color: transparent !important;
  border: none;
  outline: none;
}


.iphone-container {
  -moz-transform: scale(0.75);
  -webkit-transform: scale(0.75);
  -o-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
}

.iphone {
  position: relative;
  margin: 40px auto;
  width: 360px;
  height: 780px;
  background-color: #faf7f5;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
  
  &:before,
  &:after{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* home button indicator */
  &:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
  }

  /* frontal camera/speaker frame */
  &:before {
    top: 0px;
    width: 56%;
    height: 30px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
  }
}

.phone-speaker {
  position: absolute;
  display: block;
  color: transparent;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
}

.iphone-camera {
  position: absolute;
  display: block;
  color: transparent;
  left: 10%;
  top: 0px;
  transform: translate(180px, 4px);
  width: 12px;
  height: 12px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
  
  &:after {
    content: '';
    position: absolute;
    background-color: #2d4d76;
    width: 6px;
    height: 6px;
    top: 2px;
    left: 2px;
    top: 3px;
    left: 3px;
    display: block;
    border-radius: 4px;
    box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
  }
}

.iphone-content {
  border-radius: 40px !important;
  height: 780px !important;
}

.preview-top-bar {
  border-radius: 40px 40px 0 0;
}

.preview-bottom-bar-right {
  border-radius: 0 0 40px 0;
}

.preview-bottom-bar-left {
  border-radius: 0 0 0 40px;
}